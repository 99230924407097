import {
  AtlantisThemeContextProvider,
  Icon,
  Typography,
  useAtlantisTheme,
} from "@jobber/components";
import { useIntl } from "react-intl";
import classnames from "classnames";
import React from "react";
import type { PaymentMethodChipType } from "./PaymentMethodChips";
import styles from "./PaymentMethodChips.module.css";
import { messages } from "./messages";

interface PaymentMethodProps {
  method: PaymentMethodChipType;
  isSelected: boolean;
  onClick: () => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}

const InternalPaymentMethodChip: React.FC<PaymentMethodProps> = ({
  method,
  isSelected,
  onClick,
  onKeyDown,
}) => {
  const { formatMessage } = useIntl();
  const classes = classnames(styles.paymentMethod, {
    [styles.selected]: isSelected,
  });

  const { theme } = useAtlantisTheme();
  const fontWeight = isSelected ? "medium" : "regular";

  let iconColor;
  let textColor;
  if (theme === "light") {
    iconColor = isSelected ? "white" : "greyBlueDark";
    textColor = isSelected ? "white" : undefined;
  } else {
    iconColor = isSelected ? "greyBlueDark" : "white";
    textColor = isSelected ? "greyBlueDark" : "white";
  }

  return (
    <div
      className={classes}
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={0}
      role="button"
    >
      <div className={styles.iconWrapper}>
        {React.cloneElement(method.icon as React.ReactElement, {
          color: iconColor,
        })}
      </div>
      <Typography size="small" textColor={textColor} fontWeight={fontWeight}>
        {formatMessage(messages[method.type])}
      </Typography>
      {isSelected && (
        <div className={styles.checkIcon}>
          <Icon name="checkmark" size="small" color="interactiveSubtle" />
        </div>
      )}
    </div>
  );
};

export const PaymentMethodChip: React.FC<PaymentMethodProps> = props => {
  return (
    <AtlantisThemeContextProvider>
      <InternalPaymentMethodChip {...props} />
    </AtlantisThemeContextProvider>
  );
};

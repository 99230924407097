import React, { useCallback, useState } from "react";
import { parseISO } from "date-fns";
import { Text } from "@jobber/components/Text";
import { FormatRelativeDateTime } from "@jobber/components/FormatRelativeDateTime";
import type { FetchResult } from "@apollo/client";
import { useIntl } from "react-intl";
import { Heading } from "@jobber/components/Heading";
import { Banner } from "@jobber/components/Banner";
import { Content } from "@jobber/components/Content";
import type {
  ReviewDataFragment,
  ReviewReplyEditInput,
  ReviewReplyEditMutation,
} from "~/utilities/API/graphql";
import { TextActionButton } from "~/components/TextActionButton/TextActionButton";
import styles from "./ReviewCard.module.css";
import { messages } from "./messages";
import { StarGroup } from "../StarGroup";
import { ReplyCard } from "../ReplyCard";

export const ReviewCard = React.memo(ReviewCardInternal);

function ReviewCardInternal({
  review,
  sendReviewReply,
}: {
  review: ReviewDataFragment;
  sendReviewReply: (
    options: ReviewReplyEditInput,
  ) => Promise<FetchResult<ReviewReplyEditMutation>>;
}) {
  const { formatMessage } = useIntl();
  const [reviewReplyError, setReviewReplyError] = useState<string | undefined>(
    undefined,
  );

  const dismissErrorMessage = useCallback(() => {
    if (reviewReplyError !== undefined) {
      setReviewReplyError(undefined);
    }
  }, [reviewReplyError]);

  const setErrorMessage = useCallback(
    (message: string) => {
      if (reviewReplyError !== undefined) {
        setReviewReplyError(undefined);
      }
      setReviewReplyError(message);
    },
    [reviewReplyError],
  );

  const confirmedAttributions =
    review.clientAttributions?.confirmedReviewAttributions;
  const reviewerName = review.reviewer.isAnonymous
    ? formatMessage(messages.anonymousReviewer)
    : (review.reviewer.displayName as string);

  if (review === undefined) {
    return <></>;
  }

  return (
    <div className={styles.listItemContainer}>
      <Content type={"section"} spacing={"smaller"}>
        {reviewReplyError !== undefined && (
          <Banner type={"error"}>{reviewReplyError}</Banner>
        )}
        <StarGroup rating={review.rating ?? 0} allowHalfStars={false} />
        <div className={styles.listItemRow}>
          {confirmedAttributions && confirmedAttributions.totalCount > 0 ? (
            <TextActionButton
              label={reviewerName}
              onClick={() =>
                (window.location.href =
                  confirmedAttributions.nodes[0].client.jobberWebUri)
              }
              variation="subtle"
              type="tertiary"
            />
          ) : (
            <Heading level={5}>{reviewerName}</Heading>
          )}
          <Text size={"small"} variation={"subdued"}>
            <FormatRelativeDateTime date={parseISO(review.updatedAt)} />
          </Text>
        </div>
        <Text>{review.comment}</Text>
        <ReplyCard
          reviewComment={review.comment}
          comment={review.reviewReply?.comment}
          date={review.reviewReply?.updatedAt}
          reviewId={review.reviewId}
          sendReviewReply={sendReviewReply}
          setErrorMessage={setErrorMessage}
          dismissErrorMessage={dismissErrorMessage}
        />
      </Content>
    </div>
  );
}

import { gql } from "@apollo/client";

export const JOBBER_CAPITAL_DETAILS_SECTION_QUERY = gql`
  query jobberCapitalDetailsSection {
    capitalFinancingPromotion {
      activeFinancing
      financingHistory
      offerAvailable
    }
    user {
      id
      isAccountOwner
    }
  }
`;

import { Emphasis } from "@jobber/components/Emphasis";
import { Text } from "@jobber/components/Text";
import { useBreakpoints } from "@jobber/hooks/useBreakpoints";
import React, { type ReactElement, type ReactNode } from "react";
import { useIntl } from "react-intl";
import { Glimmer } from "@jobber/components";
import type { CampaignListFragment } from "~/utilities/API/graphql";
import { CampaignStatusLabel } from "jobber/campaigns/views/CampaignsLandingPage/components/CampaignStatusLabel/CampaignStatusLabel";
import { CampaignStatus } from "~/utilities/API/graphql";
import { AUTOMATION_RULE_TRIGGER_TYPES } from "jobber/campaigns/constants";
import { campaignStatistics, updatedAtMessage } from "./utils";
import { messages } from "../../messages";

interface useCampaignDataListItems {
  defaultSubject?: string;
  loadingDeferred?: boolean;
}

export interface CampaignsEdgeUsingFragment {
  __typename?: "CampaignsEdge";
  node: CampaignListFragment;
}

interface DataListObject {
  readonly id: string | number;
  readonly label?: string | ReactElement;
  readonly [key: string]: ReactNode | Date;
}

export interface SingleCampaign extends DataListObject {
  id: string;
  rawData: string;
  label: JSX.Element;
  emailsDelivered: ReactNode;
  openRate: ReactNode;
  clickRate: ReactNode;
  revenue: ReactNode;
  status: ReactNode;
  updatedAt: ReactNode | Date;
}

export function useCampaignDataListItems({
  defaultSubject = "",
  loadingDeferred = false,
}: useCampaignDataListItems = {}) {
  const { mediumAndUp } = useBreakpoints();
  const { formatMessage } = useIntl();

  const firstField = ({
    recipientsCount,
    friendlyName,
  }: {
    recipientsCount?: number;
    friendlyName?: string;
  }) =>
    loadingDeferred ? (
      <Glimmer />
    ) : (
      <Text>
        {formatMessage(messages.recipientsCountAndSegmentNameText, {
          recipientsCount,
          friendlyName,
        })}
      </Text>
    );

  const BASE_FIELDS = (edge: CampaignsEdgeUsingFragment) => {
    const triggerType = AUTOMATION_RULE_TRIGGER_TYPES.find(
      option => option.id === edge.node.automationRule?.trigger.task,
    )?.label;
    const friendlyName = edge.node.clientSegment
      ? edge.node.clientSegment.friendlyName
      : triggerType;
    return {
      id: edge.node.id,
      rawData: JSON.stringify(edge.node), // Hack: I want this to be the Campaigns type,
      // but I was required to serialize it to avoid DataList type restrictions
      label: (
        <>
          <Emphasis variation="bold">
            {edge.node.templates?.nodes?.[0]?.subject || defaultSubject}
          </Emphasis>
          {mediumAndUp &&
            (edge.node.status === CampaignStatus.SENT ? (
              firstField({
                recipientsCount: edge.node.totalDelivered,
                friendlyName,
              })
            ) : (
              <Text>{friendlyName}</Text>
            ))}
        </>
      ),
      openRate: loadingDeferred ? (
        <Glimmer />
      ) : (
        campaignStatistics({
          status: edge.node.status,
          value: edge.node.openRate?.toFixed(1),
          extraText: mediumAndUp
            ? formatMessage(messages.desktopPercent)
            : formatMessage(messages.percentOpen),
          emptyText: mediumAndUp ? "-" : "",
          mediumAndUp,
          countText: mediumAndUp
            ? `${formatMessage(messages.recipientsCountText, {
                recipientsCount: edge.node.totalOpened,
              })}`
            : "",
        })
      ),
      clickRate: loadingDeferred ? (
        <Glimmer />
      ) : (
        campaignStatistics({
          status: edge.node.status,
          value: edge.node.clickRate?.toFixed(1),
          extraText: mediumAndUp
            ? formatMessage(messages.desktopPercent)
            : formatMessage(messages.percentClick),
          emptyText: mediumAndUp ? "-" : "",
          countText: mediumAndUp
            ? `${formatMessage(messages.recipientsCountText, {
                recipientsCount: edge.node.totalClicked,
              })}`
            : "",
        })
      ),
      revenue: loadingDeferred ? (
        <Glimmer />
      ) : (
        campaignStatistics({
          status: edge.node.status,
          value: edge.node.revenue?.value
            ? formatMessage(messages.desktopDollar, {
                amount: edge.node.revenue.value.toFixed(2),
              })
            : undefined,
          extraText: mediumAndUp ? "" : formatMessage(messages.mobileRevenue),
          emptyText: mediumAndUp ? "-" : "",
          countText: mediumAndUp
            ? `${edge.node.revenue?.count} ${formatMessage(
                messages.revenueCountText,
              )}`
            : "",
        })
      ),
      status: (
        <CampaignStatusLabel
          status={edge.node.status}
          statusMessage={edge.node.statusMessage}
          scheduledAt={edge.node.scheduledAt}
        />
      ),
      updatedAt: updatedAtMessage(edge.node, mediumAndUp, formatMessage),
    };
  };

  const AUTOMATION_FIELDS = (edge: CampaignsEdgeUsingFragment) => {
    return {
      emailsDelivered: loadingDeferred ? (
        <Glimmer />
      ) : (
        campaignStatistics({
          status: edge.node.status,
          value: `${edge.node.totalDelivered}`,
          extraText: false,
          emptyText: mediumAndUp ? "-" : "",
          mediumAndUp,
        })
      ),
    };
  };

  return function campaignToDataListItem(
    edge: CampaignsEdgeUsingFragment,
  ): SingleCampaign {
    return {
      ...BASE_FIELDS(edge),
      ...AUTOMATION_FIELDS(edge),
    };
  };
}

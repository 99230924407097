import { Content } from "@jobber/components/Content";
import { Modal } from "@jobber/components/Modal";
import { parseISO } from "date-fns";
import { Text } from "@jobber/components/Text";
import { Banner } from "@jobber/components/Banner";
import { Button, Heading, RadioGroup, RadioOption } from "@jobber/components";
import { useIntl } from "react-intl";
import { FormatRelativeDateTime } from "@jobber/components/FormatRelativeDateTime";
import { useState } from "react";
import { StarGroup } from "legacy/jobber/reviews/views/ReviewsPage/components/StarGroup/StarGroup";
import { ReviewAttributionStatus } from "~/utilities/API/graphql";
import type { ReviewDataFragment } from "~/utilities/API/graphql";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { messages } from "./messages";
import styles from "./AttributionModal.module.css";
import { useReviewAttributionsBulkEditMutation } from "./hooks/useReviewAttributionsBulkEditMutation";
import type { AttributionOption } from "./types";

const NO_MATCH = "NO_MATCH";

interface ReviewModalProps {
  openModal: boolean;
  onRequestClose: () => void;
  review: ReviewDataFragment;
  onReviewMatchSelected?: (matchSelected: boolean) => void;
}

export function AttributionModal({
  openModal,
  onRequestClose,
  review,
  onReviewMatchSelected,
}: ReviewModalProps) {
  const { formatMessage } = useIntl();
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const { bulkEdit } = useReviewAttributionsBulkEditMutation();

  const potentialClientMatches = [
    ...(review.clientAttributions?.pendingReviewAttributions?.nodes || []),
    ...(review.clientAttributions?.confirmedReviewAttributions?.nodes || []),
    { client: { id: undefined } }, // This adds a "No Match" option
  ];

  const [selectedClient, setSelectedClient] = useState(
    potentialClientMatches?.[0].client.id ?? NO_MATCH,
  );

  const trackClose = () => {
    Amplitude.TRACK_EVENT("CTA Dismissed", {
      name: "Review Attribution Modal Dismissed",
    });
  };

  const trackSubmit = (choice: string) => {
    Amplitude.TRACK_EVENT("CTA Submitted", {
      name: "Review Attribution Match Submitted",
      value: choice,
    });
  };

  async function handleClick() {
    try {
      const result = await bulkEdit({
        variables: {
          input: mapSelectedClientToInputPayload(review, selectedClient),
        },
      });

      if (result?.data?.reviewAttributionsEdit.userErrors.length === 0) {
        onReviewMatchSelected?.(selectedClient !== NO_MATCH);
        trackSubmit(selectedClient);
        onRequestClose();
      } else {
        setShowErrorBanner(true);
      }
    } catch (error) {
      setShowErrorBanner(true);
    }
  }

  function handleClose() {
    trackClose();
    onRequestClose();
  }

  return (
    <Modal
      title={formatMessage(messages.isThereAMatchTitle)}
      open={openModal}
      onRequestClose={handleClose}
    >
      <Content spacing="small">
        {showErrorBanner && (
          <Banner type="error" onDismiss={() => setShowErrorBanner(false)}>
            {formatMessage(messages.errorMessage)}
          </Banner>
        )}
        <StarGroup rating={review.rating ?? 0} allowHalfStars={false} />
        <div className={styles.listItemRow}>
          <Heading level={5}>
            {review.reviewer.isAnonymous
              ? formatMessage(messages.anonymousReviewer)
              : formatMessage(messages.reviewerDisplayName, {
                  reviewerName: review.reviewer.displayName,
                })}
          </Heading>
          <Text size="small" variation="subdued">
            <FormatRelativeDateTime date={parseISO(review.updatedAt)} />
          </Text>
        </div>
        <Text>{review.comment}</Text>

        <div className={styles.radioGroup}>
          <RadioGroup
            value={selectedClient}
            onChange={(value: string) => setSelectedClient(value)}
            ariaLabel={formatMessage(
              messages.reviewAttributionsOptionsAriaLabel,
            )}
          >
            {mapAttributionsToRadioOptions(
              potentialClientMatches,
              formatMessage(messages.noMatch),
            )}
          </RadioGroup>
        </div>

        <div className={styles.saveButton}>
          <Button
            label={formatMessage(messages.saveLabel)}
            onClick={handleClick}
          />
        </div>
      </Content>
    </Modal>
  );
}

function mapAttributionsToRadioOptions(
  options: AttributionOption[],
  noMatchLabel: string,
) {
  return options.map(option => {
    if (option.client.id) {
      const propertiesCount = option.client.clientProperties.nodes.length;
      let label = `${propertiesCount} Properties`;

      if (propertiesCount === 1) {
        label = option.client.clientProperties?.nodes[0]?.address?.street;
      }

      return (
        <RadioOption
          key={option.client.id}
          value={option.client.id}
          label={option.client.name}
          description={label}
        />
      );
    }

    return <RadioOption key={NO_MATCH} value={NO_MATCH} label={noMatchLabel} />;
  });
}

function mapSelectedClientToInputPayload(
  review: ReviewDataFragment,
  selectedClient: string,
) {
  const pendingAttributions =
    review.clientAttributions?.pendingReviewAttributions?.nodes || [];

  const confirmedAttributions =
    review.clientAttributions?.confirmedReviewAttributions?.nodes || [];

  const attributions = [...pendingAttributions, ...confirmedAttributions];

  return attributions.map(reviewAttribution => {
    if (reviewAttribution.client.id === selectedClient) {
      return {
        id: reviewAttribution.id,
        status: ReviewAttributionStatus.CONFIRMED,
      };
    }

    return {
      id: reviewAttribution.id,
      status: ReviewAttributionStatus.DENIED,
    };
  });
}

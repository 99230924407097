import type { ApolloError } from "@apollo/client";
import { useCollectionQuery } from "@jobber/hooks/useCollectionQuery";
import { useEffect, useState } from "react";
import { GET_CAMPAIGNS_LIST_QUERY } from "jobber/campaigns/graphql";
import type {
  CampaignsFilterInput,
  GetCampaignsListQueryQuery,
} from "~/utilities/API/graphql";

export enum SORT_KEYS {
  UPDATED_AT = "UPDATED_AT",
}

export enum SORT_DIRECTION {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

interface UseCampaignsQuery {
  data?: GetCampaignsListQueryQuery;
  error?: ApolloError;
  loadingInitialContent: boolean;
  loadingNextPage: boolean;
  nextPage: () => void;
  setSortVariable: (
    sortKey: string,
    sortDirection: "asc" | "desc" | undefined,
  ) => void;
  refresh: () => void;
}
export function useCampaignsQuery(
  filter: CampaignsFilterInput,
  includeDeferredReportingData = true,
): UseCampaignsQuery {
  const [sortVariables, setSortVariables] = useState({
    key: "UPDATED_AT",
    direction: "DESCENDING",
  });
  const {
    data,
    loadingInitialContent,
    loadingNextPage,
    error,
    loadingRefresh,
    nextPage,
    refresh,
  } = useCollectionQuery<GetCampaignsListQueryQuery>({
    query: GET_CAMPAIGNS_LIST_QUERY,
    queryOptions: {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      variables: {
        sort: sortVariables,
        filter,
        includeDeferredReportingData,
      },
    },
    getCollectionByPath: campaign => campaign?.commsCampaigns,
  });

  const setSortVariable = (
    sortKey: string,
    sortDirection: "asc" | "desc" | undefined,
  ) => {
    const sortOrder =
      sortDirection && sortDirection === "asc"
        ? SORT_DIRECTION.ASCENDING
        : SORT_DIRECTION.DESCENDING;
    setSortVariables({ key: sortKey, direction: sortOrder });
  };

  return {
    data,
    error,
    loadingInitialContent: loadingRefresh || loadingInitialContent,
    loadingNextPage,
    nextPage,
    setSortVariable,
    refresh,
  };
}

interface UseCampaignsQueryWithDeferredReportingData extends UseCampaignsQuery {
  loadingDeferred: boolean;
}
export function UseCampaignsQueryWithDeferredReportingData(
  filter: CampaignsFilterInput,
): UseCampaignsQueryWithDeferredReportingData {
  // faster query
  const {
    data: lightweightData,
    error: campaignsQueryError,
    loadingInitialContent,
    setSortVariable: setSortVariableLightweight,
    refresh: refreshLightweight,
  } = useCampaignsQuery(filter, false);

  // slower query; superset of the faster query
  const {
    data: completeData,
    error: deferredCampaignsQueryError,
    loadingInitialContent: deferredLoadingInitialContent,
    loadingNextPage: deferredLoadingNextPage,
    nextPage: nextPageDeferred,
    setSortVariable: setSortVariableDeferred,
    refresh: refreshDeferred,
  } = useCampaignsQuery(filter, true);

  const nextPage = () => {
    nextPageDeferred();
  };
  const setSortVariable = (...args: [string, "asc" | "desc" | undefined]) => {
    setSortVariableDeferred(...args);
    setSortVariableLightweight(...args);
  };
  const refresh = () => {
    refreshDeferred();
    refreshLightweight();
  };

  useEffect(() => {
    if (!deferredLoadingInitialContent) {
      nextPage();
    }
  }, [deferredLoadingInitialContent]);

  return {
    data: completeData || lightweightData,
    error: campaignsQueryError || deferredCampaignsQueryError,
    loadingInitialContent,
    loadingNextPage: deferredLoadingNextPage,
    loadingDeferred: deferredLoadingInitialContent,
    nextPage,
    setSortVariable,
    refresh,
  };
}

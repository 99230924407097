import React from "react";
import { Button, Card, Content, Text } from "@jobber/components";
import { useIntl } from "react-intl";
import type { JobberCapitalDetailsSectionQuery } from "~/utilities/API/graphql";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import styles from "./style.module.css";
import { messages } from "./messages";

export interface JobberCapitalDetailsSectionProps {
  data: JobberCapitalDetailsSectionQuery;
  redirectToEmbeddedStripeView: boolean;
}

export function JobberCapitalDetailsSection({
  data,
  redirectToEmbeddedStripeView,
}: JobberCapitalDetailsSectionProps) {
  const { formatMessage } = useIntl();
  const [stripeReportLinkPath] = useUrls("stripeCapitalReportLinkPath");

  const shouldShowJobberCapitalDetails =
    data?.capitalFinancingPromotion?.activeFinancing ||
    data?.capitalFinancingPromotion?.financingHistory ||
    data?.capitalFinancingPromotion?.offerAvailable;

  const isAccountOwner = data?.user?.isAccountOwner ?? false;
  const jobberCapitalDetailsUrl = redirectToEmbeddedStripeView
    ? "/jobber_capital"
    : stripeReportLinkPath;

  if (!isAccountOwner || !shouldShowJobberCapitalDetails) {
    return <></>;
  }

  const headerAction = (
    <Button
      label="View"
      type="secondary"
      url={jobberCapitalDetailsUrl}
      fullWidth={false}
    />
  );

  return (
    <Content>
      <Card
        header={{
          title: formatMessage(messages.jobberCapitalDetailsSectionTitle),
          action: headerAction,
        }}
      >
        <div className={styles.jobberCapitalDetailsSectionText}>
          <Text>{formatMessage(messages.jobberCapitalDetailsSectionText)}</Text>
        </div>
      </Card>
    </Content>
  );
}

import { useState } from "react";
import { useIntl } from "react-intl";
import { Menu } from "@jobber/components/Menu";
import { Button } from "@jobber/components/Button";
import { useViewport } from "legacy/jobber/hooks/useViewport";
import {
  isInDesktopView,
  isInHandheldView,
} from "~/jobber/features/Reporting/components/Report/helpers/screenWidth";
import { PrivacyMask } from "components/Observability/PrivacyMask";
import {
  DateRangeSelectOptions,
  dateRangeOptionIndex,
} from "./components/DateSelector/constants";
import { FilterDisplay } from "./components/FilterDisplay/FilterDisplay";
import { dateRangeLabel } from "./util";
import { DateSelector } from "./components/DateSelector/DateSelector";
import styles from "./ReportFilters.module.css";
import { FilterSelect } from "./components/FilterSelect/FilterSelect";
import { FilterAutocomplete } from "./components/FilterAutocomplete/FilterAutocomplete";
import { messages } from "./messages";
import { messages as dateSelectorMessages } from "./components/DateSelector/messages";
import type { AppliedDateRange, FilterType, ReportFiltersProps } from "./types";
import type { DateRange } from "./components/DateSelector/types";

export function ReportFilters({
  dateRangeFilter,
  onFilterChange,
  statusFilter,
  searchFilter,
  showDateSelector = true,
  onFilterTypeChange,
  children,
  customFilterLocation = "before",
}: ReportFiltersProps) {
  const { formatMessage } = useIntl();

  const [currentDateRangeFilter, setCurrentDateRangeFilter] =
    useState<FilterType>(dateRangeFilter);

  const [currentDateRangeOption, setCurrentDateRangeOption] =
    useState<DateRangeSelectOptions>(getDateRangeOption());

  const [currentStatusOption, setCurrentStatusOption] = useState<
    string | undefined
  >(statusFilter?.value);

  const [appliedDateRangeFilter, setAppliedDateRangeFilter] =
    useState<AppliedDateRange>({
      startDate: currentDateRangeFilter.value.startDate,
      endDate: currentDateRangeFilter.value.endDate,
      dateRangeOption: currentDateRangeOption,
    });

  const { innerWidth } = useViewport();

  const handleStatusSelect = (value: string) => {
    if (onFilterTypeChange) {
      onFilterTypeChange(value);
    }
    setCurrentStatusOption(value);
  };

  const onClose = () => {
    setCurrentStatusOption(statusFilter?.value);
    if (onFilterTypeChange) {
      onFilterTypeChange(currentStatusOption);
    }
  };

  const applyFilter = () => {
    onFilterChange(currentDateRangeFilter, currentStatusOption);
    setAppliedDateRangeFilter({
      dateRangeOption: currentDateRangeOption,
      startDate: currentDateRangeFilter.value.startDate,
      endDate: currentDateRangeFilter.value.endDate,
    });
  };

  const onDateFilterChange = (range: DateRange) => {
    setCurrentDateRangeFilter({ key: "date_range", value: range });
  };

  function getDateRangeOption() {
    const foundOption = dateRangeOptionIndex.find(
      option =>
        dateRangeFilter.value.startDate === option.start &&
        dateRangeFilter.value.endDate === option.end,
    );
    return foundOption?.option
      ? (formatMessage(
          dateSelectorMessages[foundOption.option],
        ) as DateRangeSelectOptions)
      : DateRangeSelectOptions.custom;
  }

  // More filters will be added here.
  return (
    <div className={styles.filtersContainer}>
      {customFilterLocation === "before" && children}
      {statusFilter && isInDesktopView(innerWidth) && (
        <PrivacyMask asWrapper disabled>
          {statusFilter.options.length === 1 ? (
            <Button
              label={statusFilter.value}
              type="primary"
              variation="subtle"
            />
          ) : (
            <Menu
              activator={
                <Button
                  label={statusFilter.value}
                  type="primary"
                  variation="subtle"
                  iconOnRight
                  icon="arrowDown"
                />
              }
              items={[
                {
                  actions: statusFilter.options,
                },
              ]}
            />
          )}
        </PrivacyMask>
      )}

      {(showDateSelector || isInHandheldView(innerWidth)) && (
        <PrivacyMask asWrapper disabled>
          <FilterDisplay
            filterLabel={dateRangeLabel(
              dateRangeFilter.value.startDate,
              dateRangeFilter.value.endDate,
              dateRangeFilter.prefix,
            )}
            drawerLabel={formatMessage(messages.filterDrawerTitle)}
            onFilterApply={applyFilter}
            onFilterClose={onClose}
          >
            {searchFilter && isInHandheldView(innerWidth) && (
              <PrivacyMask>
                <FilterAutocomplete {...searchFilter} />
              </PrivacyMask>
            )}

            {statusFilter && isInHandheldView(innerWidth) && (
              <FilterSelect
                filterOptions={statusFilter.options.map(option => option.label)}
                defaultValue={currentStatusOption}
                onFilterSelectChange={handleStatusSelect}
                {...statusFilter}
              />
            )}

            <DateSelector
              heading={dateRangeFilter.heading}
              selectedDateRange={currentDateRangeFilter.value}
              handleDateFilterChange={onDateFilterChange}
              appliedDateRange={appliedDateRangeFilter}
              setSelectedDateRangeOption={setCurrentDateRangeOption}
              showDateSelector={showDateSelector}
            />
          </FilterDisplay>
        </PrivacyMask>
      )}
      {customFilterLocation === "after" && children}
    </div>
  );
}

import { defineMessages } from "react-intl";

const pageIdPrefix = "setupWizard.heardAboutUsPage.";

export const messages = defineMessages({
  heardAboutUsPageHeading: {
    id: `${pageIdPrefix}heardAboutUsPageHeading`,
    defaultMessage: "We'd love to know...",
    description: "Heard about us page heading for the setup wizard",
  },
  heardAboutUsPageCopy: {
    id: `${pageIdPrefix}heardAboutUsPageCopy`,
    defaultMessage:
      "How did you hear about Jobber? Your feedback helps us improve and reach more businesses like yours. Thanks for sharing!",
    description: "Heard about us page copy for the setup wizard",
  },
  hdyhauPlaceholder: {
    id: `${pageIdPrefix}hdyhauPlaceholder`,
    defaultMessage: "How did you find out about Jobber?",
    description: "How did you hear about us placeholder for the setup wizard",
  },
  heardAboutUsPageFeatureListHeading: {
    id: `${pageIdPrefix}heardAboutUsPageFeatureListHeading`,
    defaultMessage: "We're here to help your business run smoothly",
    description:
      "Heard about us page feature list heading for the setup wizard",
  },
  heardAboutUsPageFeatureListItem1Header: {
    id: `${pageIdPrefix}heardAboutUsPageFeatureListItem1Header`,
    defaultMessage: "All in one place",
    description:
      "Heard about us page feature list default item one header for the setup wizard",
  },
  heardAboutUsPageFeatureListItem1Subtext: {
    id: `${pageIdPrefix}heardAboutUsPageFeatureListItem1Subtext`,
    defaultMessage:
      "Control your entire operations in Jobber — quoting, scheduling, invoicing, and payments",
    description:
      "Heard about us page feature list default item one subtext for the setup wizard",
  },
  heardAboutUsPageFeatureListItem2Header: {
    id: `${pageIdPrefix}heardAboutUsPageFeatureListItem2Header`,
    defaultMessage: "Customer portal",
    description:
      "Heard about us page feature list default item two header for the setup wizard",
  },
  heardAboutUsPageFeatureListItem2Subtext: {
    id: `${pageIdPrefix}heardAboutUsPageFeatureListItem2Subtext`,
    defaultMessage:
      "Make it easy for customers to request work, approve quotes, and make payments online",
    description:
      "Heard about us page feature list default item two subtext for the setup wizard",
  },
  heardAboutUsPageFeatureListItem3Header: {
    id: `${pageIdPrefix}heardAboutUsPageFeatureListItem3Header`,
    defaultMessage: "Automated texts and emails",
    description:
      "Heard about us page feature list default item three header for the setup wizard",
  },
  heardAboutUsPageFeatureListItem3Subtext: {
    id: `${pageIdPrefix}heardAboutUsPageFeatureListItem3Subtext`,
    defaultMessage:
      "Save time and avoid miscommunication with customer reminders and follow-ups",
    description:
      "Heard about us page feature list default item three subtext for the setup wizard",
  },
  heardAboutUsPageFeatureListItem4Header: {
    id: `${pageIdPrefix}heardAboutUsPageFeatureListItem4Header`,
    defaultMessage: "Built-in reporting",
    description:
      "Heard about us page feature list default item four header for the setup wizard",
  },
  heardAboutUsPageFeatureListItem4Subtext: {
    id: `${pageIdPrefix}heardAboutUsPageFeatureListItem4Subtext`,
    defaultMessage:
      "Track all your jobs and team members, and monitor your business performance",
    description:
      "Heard about us page feature list default item four subtext for the setup wizard",
  },
});

import type { IntlFormatters } from "react-intl";
import { BillingCycleName } from "~/utilities/API/graphql";
import { messages } from "./messages";
import { recommendedAddOns } from "./config";

export function getDiscountHeader(
  formatMessage: IntlFormatters["formatMessage"],
  selectedBillingCycle: BillingCycleName,
  discountType?: string,
  discountAmount?: number,
  remainingDiscountedFullMonths?: number,
) {
  if (discountType && discountAmount && remainingDiscountedFullMonths) {
    const formattedDiscountAmount = getFormattedDiscountAmount(
      discountAmount,
      discountType,
    );

    if (
      selectedBillingCycle === BillingCycleName.MONTHLY &&
      remainingDiscountedFullMonths
    ) {
      const discountDurationUnit =
        remainingDiscountedFullMonths > 1 ? `months` : `month`;

      return formatMessage(messages.monthlySubscriptionSavingsMessage, {
        discountAmount: formattedDiscountAmount,
        discountDuration: remainingDiscountedFullMonths,
        discountDurationUnit: discountDurationUnit,
      });
    }

    return formatMessage(messages.annualSubscriptionSavingsMessage, {
      discountAmount: formattedDiscountAmount,
    });
  }
}

export function addonRecommended(addonIdentifier: string) {
  return recommendedAddOns.includes(addonIdentifier);
}

function getFormattedDiscountAmount(
  discountAmount: number,
  discountType: string,
) {
  return discountType === "PERCENT"
    ? `${discountAmount}%`
    : `$${discountAmount}`;
}

import React from "react";
import { useQuery } from "@apollo/client";
import { APIProvider } from "~/utilities/API/APIProvider";
import type { JobberCapitalDetailsSectionQuery } from "~/utilities/API/graphql";
import { withSplitClient } from "utilities/split";
import { JOBBER_CAPITAL_DETAILS_SECTION_QUERY } from "./JobberCapitalDetailsSectionQuery.graphql";
import { JobberCapitalDetailsSection } from "./JobberCapitalDetailsSection";
import { useStripeCapitalEmbeddedSplit } from "../useStripeCapitalEmbeddedSplit";

function InternalJobberCapitalDetailsSectionLoader() {
  const { loading, data } = useQuery<JobberCapitalDetailsSectionQuery>(
    JOBBER_CAPITAL_DETAILS_SECTION_QUERY,
  );

  const inStripeCapitalEmbeddedSplit = useStripeCapitalEmbeddedSplit();

  if (loading || !data) {
    return <></>;
  }
  return (
    <JobberCapitalDetailsSection
      data={data}
      redirectToEmbeddedStripeView={inStripeCapitalEmbeddedSplit ?? false}
    />
  );
}

function WrappedJobberCapitalDetailsSectionLoader() {
  return (
    <APIProvider>
      <InternalJobberCapitalDetailsSectionLoader />
    </APIProvider>
  );
}

export const JobberCapitalDetailsSectionLoader = withSplitClient(
  WrappedJobberCapitalDetailsSectionLoader,
);

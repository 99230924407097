import React from "react";
import { useIntl } from "react-intl";
import { Icon } from "@jobber/components/Icon";
import { Content } from "@jobber/components/Content";
// eslint-disable-next-line no-restricted-imports
import { Typography } from "@jobber/components/Typography";
import type { MessageFormat } from "jobber/setupWizard/types";
import { communityInfo } from "jobber/setupWizard/components/constants";
import styles from "./CommunityInfo.module.css";
import { messages } from "./messages";

const renderCommunityInfo = (formatFunc: (value: MessageFormat) => string) => {
  return (
    <div className={styles.communityInfo}>
      {communityInfo.map((currItem, idx) => {
        return (
          <div key={`communityInfo_${idx}`} className={styles.item}>
            <div className={styles.checkmarkWrapper}>
              <Icon name="checkmark" size="large" color="brandHighlight" />
            </div>
            <Content spacing="small">
              <div className={styles.itemHeaderSpacer}>
                <Typography
                  element="h3"
                  size="larger"
                  textColor="white"
                  fontFamily="display"
                  fontWeight="extraBold"
                >
                  {formatFunc(currItem.header)}
                </Typography>
              </div>
              <p className={styles.itemSubtext}>
                {formatFunc(currItem.subtext)}
              </p>
            </Content>
          </div>
        );
      })}
    </div>
  );
};

export function CommunityInfo() {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.communityInfoWrapper}>
      <Typography
        element="h1"
        size="jumbo"
        textColor="white"
        fontFamily="display"
        fontWeight="black"
      >
        {formatMessage(messages.communityDefaultHeading1)}
      </Typography>
      <Typography
        element="h1"
        size="jumbo"
        textColor="white"
        fontFamily="display"
        fontWeight="black"
      >
        {formatMessage(messages.communityDefaultHeading2)}
      </Typography>
      {renderCommunityInfo(formatMessage)}
    </div>
  );
}

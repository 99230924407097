import { defineMessages } from "react-intl";

export const messages = defineMessages({
  isThereAMatchTitle: {
    id: "reviews.reviewModal.isThereAMatchTitle",
    defaultMessage: "Is there a match?",
    description: "Title for review modal",
  },
  anonymousReviewer: {
    id: "reviews.reviewCard.anonymousReviewer",
    defaultMessage: "Anonymous",
    description: "Name that is displayed when the reviewer is anonymous",
  },
  reviewerDisplayName: {
    id: "reviews.reviewCard.reviewerDisplayName",
    defaultMessage: "{reviewerName}",
    description: "Name that is displayed when the reviewer is anonymous",
  },
  reviewAttributionsOptionsAriaLabel: {
    id: "reviews.reviewModal.reviewAttributionsOptionsAriaLabel",
    defaultMessage: "Review attributions options",
    description: "Aria label for review attributions options",
  },
  noMatch: {
    id: "reviews.reviewModal.noMatch",
    defaultMessage: "No match",
    description:
      "Button to indicate that the review is not for the current client",
  },
  saveLabel: {
    id: "reviews.reviewModal.saveLabel",
    defaultMessage: "Save",
    description: "Button to save the attribution",
  },
  errorMessage: {
    id: "reviews.reviewModal.errorMessage",
    defaultMessage: "An error occurred. Please try again.",
    description: "Error message when saving the attribution fails",
  },
});

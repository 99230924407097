import { defineMessages } from "react-intl";

export const messages = defineMessages({
  jobberCapitalDetailsSectionTitle: {
    id: "managedAccountsSettings.jobberCapitalDetailsSection.CardTitle",
    defaultMessage: "Jobber Capital",
    description: "Title for Jobber Capital Details Card",
  },
  jobberCapitalDetailsSectionText: {
    id: "managedAccountsSettings.jobberCapitalDetailsSection.CardText",
    defaultMessage: "Grow your business with fast, flexible financing.",
    description: "Text for Jobber Capital Details Card",
  },
});

import { defineMessages } from "react-intl";

const pageIdPrefix = "setupWizard.heardAboutUsCommunityPage.";

export const messages = defineMessages({
  serviceProviderCollageAltText: {
    id: `${pageIdPrefix}serviceProviderCollageAltText`,
    defaultMessage: "service provider collage",
    description: "Service provider collage alt text for the setup wizard",
  },
  heardAboutCommunityPageHeading: {
    id: `${pageIdPrefix}heardAboutCommunityPageHeading`,
    defaultMessage: "We'd love to know...",
    description: "Heard about community page heading for the setup wizard",
  },
  heardAboutCommunityPageCopy: {
    id: `${pageIdPrefix}heardAboutCommunityPageCopy`,
    defaultMessage:
      "How did you hear about The Home Service Community? Your feedback helps us reach more businesses like yours. Thanks for sharing!",
    description: "Heard about community page copy for the setup wizard",
  },
  hdyhacPlaceholder: {
    id: `${pageIdPrefix}hdyhacPlaceholder`,
    defaultMessage: "How did you find out about the Community?",
    description:
      "How did you hear about community placeholder for the setup wizard",
  },
  communityLoginPrimeMessage: {
    id: `${pageIdPrefix}communityLoginPrimeMessage`,
    defaultMessage:
      "Before accessing the forum for the first time, you will be asked to re-enter your email and password to complete the sign-in process.",
    description:
      "Text to inform user they will need to re-enter their email and password",
  },
});

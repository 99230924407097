import { defineMessages } from "react-intl";

export const messages = defineMessages({
  generalError: {
    id: "reviews.reviewsPage.generalErrorBanner",
    defaultMessage: "Something went wrong. Please try again later.",
    description:
      "A general warning that something has gone wrong while loading Google reviews",
  },
  credentialError: {
    id: "reviews.reviewsPage.credentialErrorBanner",
    defaultMessage:
      "Something went wrong. Please login to your Google Business Account and try again.",
    description: "An error when the user's google creds are missing",
  },
  reviewsHeader: {
    id: "reviews.reviewsPage.reviewsHeader",
    defaultMessage: "Reviews",
    description: "Large title that is displayed at the top of the page",
  },
  overviewHeader: {
    id: "reviews.reviewsPage.overviewHeader",
    defaultMessage: "Overview",
    description: "Title that is displayed at the top of the summary section",
  },
  ratingsHeader: {
    id: "reviews.reviewsPage.ratingsHeader",
    defaultMessage: "Average rating",
    description: "Title that is displayed at the top of rating summary",
  },
  smsHeader: {
    id: "reviews.reviewsPage.smsHeader",
    defaultMessage: "SMS sent",
    description: "Title that is displayed at the top of sms summary",
  },
  reviewsTotalHeader: {
    id: "reviews.reviewsPage.reviewsTotalHeader",
    defaultMessage: "Total reviews",
    description: "Title that is displayed at the top of reviews summary",
  },
  latestReviewsHeader: {
    id: "reviews.reviewsPage.latestReviewsHeader",
    defaultMessage: "Latest reviews",
    description: "Title that is displayed at the top of latest reviews",
  },
  noReviews: {
    id: "reviews.reviewsPage.noReviews",
    defaultMessage: "No reviews have been left yet",
    description: "Message that is displayed when there are no reviews",
  },
  noUnansweredReviews: {
    id: "reviews.reviewsPage.noUnansweredReviews",
    defaultMessage: "No unanswered reviews",
    description:
      "Message that is displayed when there are no unanswered reviews",
  },
  showAll: {
    id: "reviews.reviewsPage.showAll",
    defaultMessage: "Show all",
    description: "Label for the show all button",
  },
  settingsActionLabel: {
    id: "reviews.reviewsPage.settingsActionlabel",
    defaultMessage: "Manage",
    description: "Label for the settings action button",
  },
  CTAModalHeader: {
    id: "reviews.reviewsPage.CTAModalHeader",
    defaultMessage: "Customize your message",
    description: "Header for the customization CTA modal",
  },
  CTAModalButton: {
    id: "reviews.reviewsPage.CTAModalButton",
    defaultMessage: "Customize",
    description: "Button for the customization CTA modal",
  },
  CTAModalBody: {
    id: "reviews.reviewsPage.CTAModalBody",
    defaultMessage:
      "Adjust your review message template to match the tone of your business and get more reviews!",
    description: "Body text for the customization CTA modal",
  },
  clientMatchesButtonLabel: {
    id: "reviews.reviewsPage.clientMatchesButtonLabel",
    defaultMessage: "Client Matches",
    description: "Label for the client matches button",
  },
});

import React from "react";
import type { ImageType } from "jobber/setupWizard/types";
import { MediaColumn } from "jobber/setupWizard/layouts/MediaColumn";
import communityImgSrc from "@images/signup/collage-community.jpg";
import { messages as HeardAboutCommunity } from "./messages";
import { CommunityInfo } from "./components/CommunityInfo";

export const featureHighlightsImage: ImageType = {
  alt: HeardAboutCommunity.serviceProviderCollageAltText,
  src: communityImgSrc,
};

export function FeatureHighlights() {
  return (
    <MediaColumn backgroundImage={featureHighlightsImage}>
      <CommunityInfo />
    </MediaColumn>
  );
}

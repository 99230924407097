import React from "react";
import { useIntl } from "react-intl";
import type { ImageType, SetupWizardStepProps } from "jobber/setupWizard/types";
import communityImgSrc from "@images/signup/collage-community.jpg";
import { FormColumn } from "jobber/setupWizard/layouts/FormColumn";
import { TwoColumn } from "jobber/setupWizard/layouts/TwoColumn";
import { HeardAboutCommunityPageForm } from "./components/HeardAboutCommunityPageForm";
import { messages } from "./messages";
import { FeatureHighlights } from "../FeatureHighlights";

export const communityPageImage: ImageType = {
  alt: messages.serviceProviderCollageAltText,
  src: communityImgSrc,
};

export function HeardAboutCommunityPage(stepProps: SetupWizardStepProps) {
  const { formatMessage } = useIntl();

  return (
    <TwoColumn
      leftComponent={
        <FormColumn
          heading={formatMessage(messages.heardAboutCommunityPageHeading)}
          copy={formatMessage(messages.heardAboutCommunityPageCopy)}
          form={<HeardAboutCommunityPageForm {...stepProps} />}
        />
      }
      rightComponent={<FeatureHighlights />}
    />
  );
}

import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Heading } from "@jobber/components/Heading";
import { TwoColumn } from "jobber/setupWizard/layouts/TwoColumn";
import type { SetupWizardStepProps } from "jobber/setupWizard/types";
import { FormColumn } from "jobber/setupWizard/layouts/FormColumn";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ANALYTICS_EVENTS } from "jobber/setupWizard/analytics";
import { messages as CommunityPageFormMessages } from "./components/messages";
import { CommunityPageForm } from "./components/CommunityPageForm";
import { FeatureHighlights } from "../FeatureHighlights";

export function CommunityPage(stepProps: SetupWizardStepProps) {
  useEffect(() => {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.viewedSetupWizard.eventName, {
      step: ANALYTICS_EVENTS.viewedSetupWizard.validSteps.profile,
    });
  }, []);

  const pageHeading = (
    <div data-testid="community-heading">
      <Heading level={2}>
        <FormattedMessage
          {...CommunityPageFormMessages.communityHeading}
          values={{
            span: chunks => <span className="highlighter">{chunks}</span>,
          }}
        />
      </Heading>
    </div>
  );

  return (
    <TwoColumn
      leftComponent={
        <FormColumn
          heading={pageHeading}
          copy={""}
          form={<CommunityPageForm {...stepProps} />}
        />
      }
      rightComponent={<FeatureHighlights />}
    />
  );
}

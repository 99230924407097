import { defineMessages } from "react-intl";

const pageIdPrefix = "setupWizard.communityPage.";

export const messages = defineMessages({
  communityDefaultHeading1: {
    id: `${pageIdPrefix}communityDefaultHeading1`,
    defaultMessage: "Built by you.",
    description: "Community page heading one for the setup wizard",
  },
  communityDefaultHeading2: {
    id: `${pageIdPrefix}communityDefaultHeading2`,
    defaultMessage: "Powered by Jobber.",
    description: "Community page heading two for the setup wizard",
  },
});

import React from "react";
import { Heading } from "@jobber/components";
import { useIntl } from "react-intl";
import { messages } from "./messages";
import { useDepositedPayouts } from "./useDepositedPayouts";
import { DepositedPayouts } from "./DepositedPayouts";
import { LoadingLayout } from "../LoadingLayout";
import { FinancialInsightCardWithButtonWrapper } from "../FinancialInsightCardWithButtonWrapper";

export const DepositedPayoutsLoader = () => {
  const { formatMessage } = useIntl();
  const {
    loading,
    monthAmount,
    monthEnd,
    monthStart,
    weekAmount,
    weekEnd,
    weekStart,
  } = useDepositedPayouts();
  let cardContent;

  if (loading) {
    cardContent = <LoadingLayout />;
  } else {
    cardContent = (
      <DepositedPayouts
        monthAmount={monthAmount}
        monthEnd={monthEnd}
        monthStart={monthStart}
        weekAmount={weekAmount}
        weekEnd={weekEnd}
        weekStart={weekStart}
      />
    );
  }

  return (
    <FinancialInsightCardWithButtonWrapper>
      <Heading level={3}>
        {formatMessage(messages.depositedPayoutsHeading)}
      </Heading>
      {cardContent}
    </FinancialInsightCardWithButtonWrapper>
  );
};

import React, { useEffect, useState } from "react";
import { Page } from "@jobber/components/Page";
import { useIntl } from "react-intl";
import { Banner } from "@jobber/components/Banner";
import { Tab, Tabs } from "@jobber/components";
import { useHistory } from "react-router-dom";
import { useCommsCampaignTemplateQuery } from "jobber/campaigns/hooks/useCommsCampaignTemplateQuery/useCommsCampaignTemplateQuery";
import {
  activatedCampaignsAmplitudeEvent,
  updatedCampaignAmplitudeEvent,
} from "jobber/campaigns/amplitude/events";
import { APIProvider } from "~/utilities/API/APIProvider";
import { IntlProvider } from "@translations/IntlProvider";
import { useCampaignDataListItems } from "jobber/campaigns/views/CampaignsLandingPage/hooks";
import { CampaignsFilterFields } from "~/utilities/API/graphql";
import { SentDelighterModal } from "jobber/campaigns/components/SentDelighterModal/SentDelighterModal";
import { DeleteCampaignModal } from "jobber/campaigns/views/CampaignsLandingPage/components/DeleteCampaignModal/DeleteCampaignModal";
import { PauseAndEditModal } from "jobber/campaigns/components/PauseAndEditModal/PauseAndEditModal";
import { SendNowModal } from "jobber/campaigns/components/SendNowModal/SendNowModal";
import { AutomatedCampaignList } from "jobber/campaigns/views/CampaignsLandingPage/components/AutomatedCampaignList/AutomatedCampaignList";
import { useCampaignsQueryVariables } from "jobber/campaigns/views/CampaignsLandingPage/hooks/useCampaignsQueryVariables";
import { UseCampaignsQueryWithDeferredReportingData } from "jobber/campaigns/views/CampaignsLandingPage/hooks/useCampaignsQuery";
import { ReactivateCampaignModal } from "jobber/campaigns/components/ReactivateCampaignModal/ReactivateCampaignModal";
import { DeactivateCampaignModal } from "jobber/campaigns/components/DeactivateCampaignModal/DeactivateCampaignModal";
import { AutomatedPauseAndEditModal } from "jobber/campaigns/components/AutomatedPauseAndEditModal/AutomatedPauseAndEditModal";
import { useUrlQueryParam } from "jobber/campaigns/hooks/useUrlQueryParam/useUrlQueryParam";
import {
  CAMPAIGNS_LANDING_PAGE_PATH,
  CAMPAIGNS_TEMPLATE_PATH,
  URL_PARAM_CAMPAIGNS_TYPE,
} from "jobber/campaigns/constants";
import { messages } from "./messages";
import styles from "./CampaignsLandingPage.module.css";
import { CampaignList } from "./components/CampaignList/CampaignList";

export function CampaignsLandingPage() {
  return (
    <APIProvider>
      <IntlProvider>
        <CampaignsLandingPageWrapper />
      </IntlProvider>
    </APIProvider>
  );
}

/* eslint-disable max-statements */
function CampaignsLandingPageWrapper(): JSX.Element {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { urlParam } = useUrlQueryParam(URL_PARAM_CAMPAIGNS_TYPE);

  const [selectedCampaignId, setSelectedCampaignId] = useState(""); // used for delete campaign modal

  const [campaignActionsError, setCampaignActionsError] = useState<
    string | undefined
  >();

  const {
    filter,
    actions: { setFilterType },
  } = useCampaignsQueryVariables(urlParam as CampaignsFilterFields);

  const {
    data,
    error: campaignsQueryError,
    loadingInitialContent,
    loadingNextPage,
    loadingDeferred,
    nextPage,
    setSortVariable,
    refresh,
  } = UseCampaignsQueryWithDeferredReportingData(filter.filter);

  const { loading: loadingTemplate, template } =
    useCommsCampaignTemplateQuery();

  const totalCount = data?.commsCampaigns.totalCount;
  const campaignDataList = useCampaignDataListItems({
    defaultSubject: template?.subject,
    loadingDeferred,
  });

  const campaigns = data?.commsCampaigns?.edges?.map(campaignDataList) || [];

  useEffect(() => {
    activatedCampaignsAmplitudeEvent({
      interaction: "Page Viewed",
    });
  }, []);

  const dismissErrorBanner = () => {
    setCampaignActionsError(undefined);
  };

  useEffect(() => {
    if (urlParam && urlParam.includes(CampaignsFilterFields.AUTOMATED)) {
      setFilterType(urlParam as CampaignsFilterFields);
    }
  }, [setFilterType, urlParam]);

  const handleOnClick = (type: CampaignsFilterFields) => {
    history.replace(
      `${CAMPAIGNS_LANDING_PAGE_PATH}?${URL_PARAM_CAMPAIGNS_TYPE}=${type}`,
    );
    setFilterType(type);
  };

  return (
    <div className={styles.container}>
      <Page
        title={formatMessage(messages.campaigns)}
        width="fill"
        primaryAction={{
          label: formatMessage(messages.newCampaignLabel),
          to: CAMPAIGNS_TEMPLATE_PATH,
          onClick: () =>
            updatedCampaignAmplitudeEvent({
              interaction: "Started",
              entry_point: "CTA", // eslint-disable-line @typescript-eslint/naming-convention
            }),
        }}
      >
        {campaignActionsError && !campaignsQueryError && (
          <Banner type={"error"} onDismiss={dismissErrorBanner}>
            {campaignActionsError}
          </Banner>
        )}
        {campaignsQueryError && (
          <Banner type={"error"}>{formatMessage(messages.errorMessage)}</Banner>
        )}

        {!campaignsQueryError && (
          <div className={styles.campaignListTabContainer}>
            <Tabs key={filter.tabIndex} defaultTab={filter.tabIndex}>
              <Tab
                label={formatMessage(messages.oneOffCampaignsTabTitle)}
                onClick={() => handleOnClick(CampaignsFilterFields.ONEOFF)}
              >
                <CampaignList
                  campaigns={campaigns}
                  totalCount={totalCount}
                  loadingInitial={loadingInitialContent || loadingTemplate}
                  loadingMore={loadingNextPage}
                  setVariablesForSorting={setSortVariable}
                  loadMore={nextPage}
                  setSelectedCampaignId={setSelectedCampaignId}
                />
              </Tab>
              <Tab
                label={formatMessage(messages.automatedCampaignsTabTitle)}
                onClick={() => handleOnClick(CampaignsFilterFields.AUTOMATED)}
              >
                <AutomatedCampaignList
                  campaigns={campaigns}
                  totalCount={totalCount}
                  loadingInitial={loadingInitialContent || loadingTemplate}
                  loadingMore={loadingNextPage}
                  setVariablesForSorting={setSortVariable}
                  loadMore={nextPage}
                  setSelectedCampaignId={setSelectedCampaignId}
                />
              </Tab>
            </Tabs>
          </div>
        )}
      </Page>

      <PauseAndEditModal setCampaignActionsError={setCampaignActionsError} />
      <ReactivateCampaignModal
        setCampaignActionsError={setCampaignActionsError}
      />
      <DeactivateCampaignModal
        setCampaignActionsError={setCampaignActionsError}
      />
      <AutomatedPauseAndEditModal
        setCampaignActionsError={setCampaignActionsError}
      />
      <SendNowModal
        setCampaignActionsError={setCampaignActionsError}
        refreshCampaignList={refresh}
      />
      <SentDelighterModal />
      <DeleteCampaignModal
        isOpen={!!selectedCampaignId}
        campaignId={selectedCampaignId}
        setSelectedCampaignId={setSelectedCampaignId}
        setCampaignActionsError={setCampaignActionsError}
        refreshCampaignList={refresh}
      />
    </div>
  );
}
